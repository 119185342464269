export const IS_PROD = import.meta.env.PROD;

export const APP_VERSION = String(import.meta.env.VITE_VERSION);
export const APP_ENVIRONMENT = String(import.meta.env.VITE_ENVIRONMENT);

export const SENTRY_DSN = String(import.meta.env.VITE_SENTRY_DSN);

export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL ?? '';
export const END_USER_BASE_URL = import.meta.env.VITE_END_USER_BASE_URL ?? '';
export const IMGPROXY_BASE_URL = import.meta.env.VITE_IMGPROXY_BASE_URL ?? '';
export const IS_MAINTENANCE_ENABLED =
  import.meta.env.VITE_IS_MAINTENANCE_ENABLED === 'true';
export const IS_MAINTENANCE_BANNER_ENABLED =
  import.meta.env.VITE_IS_MAINTENANCE_BANNER_ENABLED === 'true';
export const MAINTENANCE_START_TIMESTAMP =
  import.meta.env.VITE_MAINTENANCE_START_TIMESTAMP ?? '';
export const MAINTENANCE_END_TIMESTAMP =
  import.meta.env.VITE_MAINTENANCE_END_TIMESTAMP ?? '';
export const DEFAULT_EMAIL_FROM_DOMAIN =
  import.meta.env.VITE_DEFAULT_EMAIL_FROM_DOMAIN ?? '';
export const FIREBASE_FILE_SIZE_LIMIT = Number(
  import.meta.env.VITE_FIREBASE_FILE_SIZE_LIMIT ?? 0,
);
export const DEBOUNCE_DELAY_MS = 500;
