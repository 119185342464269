<template>
  <component
    :is="props.icon"
    :width="props.size"
    :height="props.size"
    aria-hidden="true"
    class="inline-block"
  />
</template>

<script setup lang="ts">
import { type Component } from 'vue';
import { IconSize } from './icon.constants';

type Props = {
  icon: Component;
  size?: IconSize;
};

const props = withDefaults(defineProps<Props>(), {
  size: IconSize.MD,
});
</script>
