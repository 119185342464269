import { ref, readonly } from 'vue';
import { defineStore } from 'pinia';
import { isAxiosError } from 'axios';
import type { Router } from 'vue-router';

export const useErrorStore = defineStore('error', () => {
  const isError = ref<boolean>(false);

  const handleError = (router: Router, err: unknown): void => {
    console.error(err);

    if (!isAxiosError(err) || !err.response) {
      isError.value = true;

      return;
    }

    if (err.response.status === 401 || err.response.status === 403) {
      router
        .push({
          path: '/auth/logout',
          query: { redirect: router.currentRoute.value.fullPath },
        })
        .catch(() => {
          isError.value = true;
        });

      return;
    }

    if (
      err.response.status === 404 ||
      err.response.data?.code === 'ORGANIZATION_ID_MISSING'
    ) {
      router
        .replace({
          name: 'notFound',
          params: { '0': router.currentRoute.value.path },
          hash: router.currentRoute.value.hash,
          query: router.currentRoute.value.query,
        })
        .catch(() => {
          isError.value = true;
        });

      return;
    }

    isError.value = true;
  };

  return { isError: readonly(isError), handleError };
});
