<template>
  <div :class="spinnerClass" />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { SpinnerSize } from '@/components/base/spinner/spinner.constants';

type Props = {
  size?: SpinnerSize;
};

const props = withDefaults(defineProps<Props>(), {
  size: SpinnerSize.MD,
});

const spinnerClass = computed(() => {
  return ['spinner', `size-${props.size}`];
});
</script>

<style scoped>
.spinner {
  @apply border-primary-95 border-2 border-t-primary border-t-2 rounded-full animate-spin;
}

.size- {
  &sm {
    @apply w-6 h-6;
  }
  &md {
    @apply w-12 h-12;
  }
}
</style>
