export enum IconSize {
  XXS = '0.5rem',
  XS = '0.75rem',
  SM = '1rem',
  MD = '1.5rem',
  LG = '2rem',
  XL = '2.5rem',
  '2XL' = '3rem',
  '3XL' = '3.5rem',
}
