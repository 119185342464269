import { ref, onMounted, onUnmounted, computed } from 'vue';

export const useWindowWidth = () => {
  const windowWidth = ref<number>(0);

  const onWidthChange = () => {
    windowWidth.value = window.innerWidth;
  };

  const isMobileView = computed(() => {
    return windowWidth.value < 400;
  });

  onMounted(() => {
    windowWidth.value = window.innerWidth;
    window.addEventListener('resize', onWidthChange);
  });

  onUnmounted(() => window.removeEventListener('resize', onWidthChange));

  return { windowWidth, isMobileView };
};
