import { apiClient } from '@/api/index';
import { components } from '~/api-types';

export enum InvitationCodeStatus {
  VALID = 'valid',
  INVALID = 'invalid',
}

export type UserDto = components['schemas']['UserResponseDto'];
export type RegisterUserBodyDto = components['schemas']['RegisterUserBodyDto'];
export type ForgotPasswordBodyDto =
  components['schemas']['ForgotPasswordBodyDto'];
export type UpdateUserBodyDto = components['schemas']['UpdateAuthUserBodyDto'];

export const apiGetAuthUser = async (): Promise<UserDto> => {
  const { data } = await apiClient.get('/auth/me');

  return data;
};

export const apiRegisterUser = async (
  payload: RegisterUserBodyDto,
): Promise<UserDto> => {
  const { data } = await apiClient.post('/auth/register', payload);

  return data;
};

export const apiSendPasswordResetEmail = (
  payload: ForgotPasswordBodyDto,
): Promise<void> => {
  return apiClient.post(`/auth/forgot-password`, payload);
};

export const apiUpdateAuthUser = async (
  payload: UpdateUserBodyDto,
): Promise<UserDto> => {
  const { data } = await apiClient.patch('/auth/me', payload);

  return data;
};

export type CheckInvitationCodeBodyDto =
  components['schemas']['CheckInvitationCodeBodyDto'];
export type CheckInvitationCodeResponseDto =
  components['schemas']['CheckInvitationCodeResponseDto'];

export const apiCheckInvitationCode = (
  payload: CheckInvitationCodeBodyDto,
): Promise<CheckInvitationCodeResponseDto> => {
  return apiClient.post(`/auth/check-invitation-code-status`, payload);
};

export const apiLogin = async (
  idToken: string,
): Promise<components['schemas']['LoginResponseDto']> => {
  const { data } = await apiClient.post(`/auth/login`, { idToken });

  return data;
};
