import { firebaseAuth } from '@/config/firebase';
import { customAlphabet } from 'nanoid/non-secure';
import { alphanumeric } from 'nanoid-dictionary';
import { FileExportFormat } from '@/types/files';
import { useAuthUserStore } from '@/store/use-auth-user-store';
import { storeToRefs } from 'pinia';

const nanoid = customAlphabet(alphanumeric, 20);

export const hasFileUrlExtensions = (extensions: string[], fileUrl: string) => {
  try {
    const { pathname } = new URL(fileUrl);
    return extensions.some((extension) => pathname.endsWith(extension));
  } catch {
    return false;
  }
};

export const saveFile = (data: Blob | string, fileName: string) => {
  const tempAnchor = document.createElement('a');

  tempAnchor.setAttribute(
    'href',
    typeof data === 'string' ? data : URL.createObjectURL(data),
  );
  tempAnchor.setAttribute('download', fileName);
  tempAnchor.click();

  URL.revokeObjectURL(tempAnchor.href);
};

export const createStorageFilePath = (fileName: string) => {
  const authUserStore = useAuthUserStore();
  const { authUser } = storeToRefs(authUserStore);

  const extension = fileName.slice(fileName.lastIndexOf('.') + 1);

  return `${authUser.value?.firebaseId}/${nanoid()}.${extension}`;
};

export const getFileExportResponseType = (exportFormat: FileExportFormat) =>
  exportFormat === FileExportFormat.XLSX ? 'arraybuffer' : 'json';
