<template>
  <Teleport to="#modals">
    <div
      ref="backdropRef"
      class="backdrop"
      tabindex="0"
      @click.self="closeModal"
      @keydown.esc="closeModal"
    >
      <slot></slot>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useScrollLock } from '@/composables/use-scroll-lock';
import { useIntersectionObserver } from '@/composables/use-intersection-observer';

type Props = {
  persist?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const { lockScroll, unlockScroll } = useScrollLock();

const backdropRef = ref<null | HTMLElement>(null);

watch(backdropRef, (value) => {
  if (value) {
    useIntersectionObserver({
      elementToWatch: value,
      callback: lockScroll,
      outCallback: unlockScroll,
      options: { threshold: 0.8 },
    });
  }
});

const closeModal = () => {
  if (!props.persist) {
    emit('close');
  }
};
</script>

<style scoped>
.backdrop {
  @apply fixed inset-0 z-50 flex flex-col justify-center items-center cursor-default;
  background: rgba(0, 0, 0, 0.2);
}
</style>
