import { defineStore } from 'pinia';
import { ref, readonly, computed } from 'vue';
import { apiGetAuthUser, UserDto } from '@/api/auth';
import { AccountDto, AccountType } from '@/api/account';
import { useLocalStorage } from '@vueuse/core';

export const useAuthUserStore = defineStore('authUser', () => {
  const token = useLocalStorage<string>('ex-token', null);

  const authUser = ref<null | UserDto>(null);
  const authAccount = ref<null | AccountDto>(null);

  const isAdmin = computed(() => authAccount.value?.type === AccountType.ADMIN);

  const setAuthUser = (data: UserDto): void => {
    authUser.value = data;
  };
  const clearAuthUser = (): void => {
    token.value = null;
    authUser.value = null;
  };

  const setAuthAccount = (data: AccountDto): void => {
    authAccount.value = data;
  };
  const clearAuthAccount = (): void => {
    authAccount.value = null;
  };

  let isInitialized = false;
  const initializeAuthUser = async (): Promise<void> => {
    if (isInitialized) {
      return;
    }

    try {
      const res = await apiGetAuthUser();

      setAuthUser(res);
    } catch {
      token.value = null;
      //
    }

    isInitialized = true;
  };

  return {
    token,
    authUser: readonly(authUser),
    isAdmin,
    setAuthUser,
    clearAuthUser,
    setAuthAccount,
    clearAuthAccount,
    initializeAuthUser,
  };
});
