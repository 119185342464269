import { defineStore } from 'pinia';
import { ref, readonly } from 'vue';

export const useLoaderStore = defineStore('loader', () => {
  const isLoading = ref<boolean>(false);

  const startLoading = (): void => {
    isLoading.value = true;
  };
  const stopLoading = (): void => {
    isLoading.value = false;
  };

  return { isLoading: readonly(isLoading), startLoading, stopLoading };
});
