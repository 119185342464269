import { createApp } from 'vue';
import { createPinia } from 'pinia';
import Notifications from '@kyvg/vue3-notification';
import { init } from '@sentry/vue';

import '@fontsource-variable/inter';
import './assets/css/main.css';

import {
  IS_PROD,
  APP_VERSION,
  APP_ENVIRONMENT,
  SENTRY_DSN,
  IS_MAINTENANCE_ENABLED,
} from '@/config/common';
import App from './App.vue';
import Maintenance from '@/views/Maintenance.vue';
import router from './router';
import { useErrorStore } from '@/store/use-error-store';
import { IGNORED_SENTRY_ERRORS } from '@/constants/sentry';

const app = createApp(IS_MAINTENANCE_ENABLED ? Maintenance : App);

app.config.errorHandler = (err: unknown) => {
  const { handleError } = useErrorStore();

  handleError(router, err);
};

if (IS_PROD) {
  init({
    app,
    dsn: SENTRY_DSN,
    tracesSampleRate: 1,
    environment: APP_ENVIRONMENT,
    release: APP_VERSION,
    ignoreErrors: IGNORED_SENTRY_ERRORS,
  });
}

app.use(createPinia()).use(router).use(Notifications).mount('#app');

console.log(`Application version: ${APP_VERSION}`);
