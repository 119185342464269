import { apiClient } from '@/api/index';
import { components, operations } from '~/api-types';

export type OrganizationDto = components['schemas']['OrganizationResponseDto'];
export type PaginatedOrganizationDto =
  components['schemas']['PaginatedOrganizationResponseDto'];

export type GetOrganizationsListQueryParams =
  operations['AdminGetOrganizationsListEndpoint_handler']['parameters']['query'];
export type UpdateCurrentOrganizationBodyDto =
  components['schemas']['UpdateCurrentOrganizationBodyDto'];
export type AdminCreateOrganizationBodyDto =
  components['schemas']['AdminCreateOrganizationBodyDto'];
export type AdminUpdateOrganizationBodyDto =
  components['schemas']['AdminUpdateOrganizationBodyDto'];
export type MenuItemDto = components['schemas']['MenuItemResponseDto'];
export type UpdateOrCreateMenuItemDto =
  components['schemas']['UpdateOrCreateMenuItemDto'];

export const apiGetCurrentOrganization = async (): Promise<OrganizationDto> => {
  const { data } = await apiClient.get('/admin/organizations/current');

  return data;
};

export const apiGetOrganizationsList = async (
  params?: GetOrganizationsListQueryParams,
): Promise<PaginatedOrganizationDto> => {
  const { data } = await apiClient.get('/admin/organizations', {
    params,
  });

  return data;
};

export const apiUpdateCurrentOrganization = async (
  payload: Partial<UpdateCurrentOrganizationBodyDto>,
): Promise<OrganizationDto> => {
  const { data } = await apiClient.patch(
    '/admin/organizations/current',
    payload,
  );

  return data;
};

export const apiCreateOrganization = async (
  payload: AdminCreateOrganizationBodyDto,
): Promise<OrganizationDto> => {
  const { data } = await apiClient.post('/admin/organizations', payload);

  return data;
};

export const apiSuperAdminUpdateOrganization = async (
  payload: AdminUpdateOrganizationBodyDto,
): Promise<OrganizationDto> => {
  const { data } = await apiClient.patch('/admin/organizations', payload);

  return data;
};

export const apiVerifyCurrentOrganizationEmailDomain =
  async (): Promise<OrganizationDto> => {
    const { data } = await apiClient.post(`/admin/organizations/verify-domain`);

    return data;
  };
