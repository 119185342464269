import { getCurrentInstance } from 'vue';
import { LocationQueryValue, RouteParamValue } from 'vue-router';

export const getUid = () => Math.random().toString(36).slice(2, 7);

export const useNotify = () => {
  const { proxy } = getCurrentInstance()!;

  return proxy!.$notify;
};

export const toggleItemExistenceInArray = <T>(
  array: T[],
  item: T,
  isSelected: boolean,
): T[] => {
  if (isSelected) {
    return [...array, item];
  }
  return array.filter((selectedItem) => selectedItem !== item);
};

export const getFirstQueryParam = (
  param?: LocationQueryValue | LocationQueryValue[],
) => (Array.isArray(param) ? param[0] : param);

export const getFirstPathParam = (
  param?: RouteParamValue | RouteParamValue[],
): string | undefined => (Array.isArray(param) ? param[0] : param);
