import { defineStore } from 'pinia';
import { ref, readonly } from 'vue';
import { apiGetCurrentAccount } from '@/api/account';
import { useAuthUserStore } from '@/store/use-auth-user-store';
import { OrganizationDto } from '@/api/organizations';
import { Nullable } from '@/types/common';

export const useCurrentOrganizationStore = defineStore(
  'currentOrganization',
  () => {
    const currentOrganization = ref<Nullable<OrganizationDto>>(null);

    const setCurrentOrganization = (data: OrganizationDto | null): void => {
      currentOrganization.value = data;
    };

    const clearCurrentOrganization = (): void => {
      currentOrganization.value = null;
    };

    const loadCurrentOrganization = async (organizationSlug: string) => {
      if (
        !currentOrganization.value ||
        currentOrganization.value.slug !== organizationSlug
      ) {
        const authUserStore = useAuthUserStore();
        const { setAuthAccount } = authUserStore;

        const account = await apiGetCurrentAccount(organizationSlug);
        setAuthAccount(account);

        setCurrentOrganization(account.organization);
      }
    };

    return {
      currentOrganization: readonly(currentOrganization),
      setCurrentOrganization,
      clearCurrentOrganization,
      loadCurrentOrganization,
    };
  },
);
