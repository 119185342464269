import axios, { InternalAxiosRequestConfig } from 'axios';
import { API_BASE_URL } from '@/config/common';
import { useCurrentOrganizationStore } from '@/store/use-current-organization-store';
import { useAuthUserStore } from '@/store/use-auth-user-store';

const LS_CLIENT_ID = 'ex-client-id';

const getClientId = (): string => {
  let clientId = localStorage.getItem(LS_CLIENT_ID);

  if (!clientId) {
    clientId = crypto.randomUUID();
    localStorage.setItem(LS_CLIENT_ID, clientId);
  }

  return clientId;
};

const onFulfilled = (config: InternalAxiosRequestConfig) => {
  config.headers.set('client-id', getClientId());
  config.headers.set('request-id', crypto.randomUUID());

  const { token } = useAuthUserStore();
  if (token) {
    config.headers.setAuthorization(`Bearer ${token}`);
  }

  const { currentOrganization } = useCurrentOrganizationStore();

  if (!config.headers.get('organization-slug') && currentOrganization) {
    config.headers.set('organization-slug', currentOrganization.slug);
  }

  return config;
};

export const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

apiClient.interceptors.request.use(onFulfilled);
