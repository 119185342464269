<template>
  <div class="flex min-h-screen items-center justify-center px-6 text-center">
    <div>
      <h1 class="font-bold text-5xl">500</h1>
      <h2 class="font-bold text-4xl mt-13">Ooops! Internal server error</h2>
      <div class="font-bold mt-7">
        Please try to refresh this page or feel free to contact us if the
        problem persists
      </div>
    </div>
  </div>
</template>
