export const useIntersectionObserver = ({
  elementToWatch,
  callback,
  outCallback,
  options,
}: {
  elementToWatch: HTMLElement;
  callback: () => void;
  outCallback: () => void;
  options?: IntersectionObserverInit;
}) => {
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting) {
      callback();
    } else {
      outCallback();
    }
  }, options);

  observer.observe(elementToWatch);

  return observer;
};
