<template>
  <div class="min-h-20 mt-2 flex rounded-lg overflow-hidden">
    <div class="icon-area" :class="`type-${props.type}`">
      <icon :size="IconSize.LG" :icon="iconTypeMap[props.type]" />
    </div>
    <div class="content-area" :class="`type-${props.type}`">
      {{ props.notification.title }}
      <btn
        class="text-secondary"
        :size="BtnSize.SM"
        icon
        @click="emit('close')"
      >
        <icon :size="IconSize.SM" :icon="CloseSvg" />
      </btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NotificationType } from '@/components/base/notification/notification.constants';
import { Icon, IconSize } from '@/components/base/icon';
import { Btn, BtnSize } from '@/components/base/btn';
import SuccessSvg from '@/assets/icons/success.svg?component';
import InfoSvg from '@/assets/icons/info.svg?component';
import ErrorSvg from '@/assets/icons/error.svg?component';
import CloseSvg from '@/assets/icons/close.svg?component';

type Props = {
  type: NotificationType;
  notification: Record<string, unknown>;
};
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'close'): void;
}>();

const iconTypeMap: Record<NotificationType, unknown> = {
  [NotificationType.SUCCESS]: SuccessSvg,
  [NotificationType.INFO]: InfoSvg,
  [NotificationType.ERROR]: ErrorSvg,
};
</script>

<style scoped>
.icon-area {
  @apply w-18 flex items-center justify-center text-white;

  &.type- {
    &success {
      @apply bg-green;
    }
    &info {
      @apply bg-primary;
    }
    &error {
      @apply bg-red;
    }
  }
}
.content-area {
  @apply flex-1 flex justify-between items-center p-4 bg-white font-semibold;

  &.type- {
    &success {
      @apply text-green;
    }
    &info {
      @apply text-primary;
    }
    &error {
      @apply text-red;
    }
  }
}
</style>
